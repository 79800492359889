<template>
  <div class="addr-page">
    <Empty photo="4" label="暂无服务地址" v-if="list.length == 0"></Empty>
    <div class="addr-list" v-else>
      <van-swipe-cell v-for="item in list" :key="item.id">
        <div class="addr-card van-hairline--bottom">
          <div class="addr-info">
            <div class="title">
              <div class="username van-ellipsis">{{ item.username }}</div>
              <div class="mobile">{{ item.mobile }}</div>
            </div>
            <div class="desc van-multi-ellipsis--l2">
              {{ item.province }} {{ item.city }} {{ item.area }} {{ item.address }}
            </div>
          </div>
          <div class="edit-btn" @click="$router.push('/address/edit/' + item.id)">
            <img src="~@/assets/images/icon/icon_edit.svg" alt="">
          </div>
        </div>
        <template #right>
          <van-button square type="danger" text="删除" class="delete-btn" @click="onDelete(item.id)"/>
        </template>
      </van-swipe-cell>
    </div>
    <van-button type="info" class="submit-btn flex-center" @click="$router.push('/address/add')">
      <span>添加服务地址</span>
    </van-button>

    <!-- 地址弹窗 -->
    <!-- <van-popup v-model="visible" position="bottom" :style="{ height: '100%' }">

    </van-popup> -->
    
  </div>
</template>

<script>
  import Empty from '@/components/empty'
  import { SwipeCell, Button, Popup, Toast, Dialog } from 'vant';
  import { getUserAddressList, deleteAddress } from '@/api/common'

  export default {
    components: {
      Empty,
      [SwipeCell.name]: SwipeCell,
      [Button.name]: Button,
      [Popup.name]: Popup,
    },
    data() {
      return {
        visible: false,
        list: []
      }
    },
    created() {
      this.loadData()
    },
    methods: {
      loadData() {
        const toast = Toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        getUserAddressList().then(res => {
          this.list = res.data.list
          toast.clear()
        })
      },
      /**
       * 添加地址
       */
      showNewDialog() {
        this.visible = true
      },
      /**
       * 删除地址
       */
      onDelete(id) {
        Dialog.confirm({
          title: '删除',
          message: '删除后将无法找回，确认删除？',
          confirmButtonColor: '#186AFF'
        })
        .then(() => {
          const toast = Toast.loading({
            message: '',
            forbidClick: true,
          })
          deleteAddress({ids: id}).then(() => {
            toast.clear()
            this.loadData()
          })
        })
      }
    },
  }
</script>

<style lang="less" scoped>
  @import './style.less';
</style>